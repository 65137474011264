<template>
    <table-container>
        <data-iterator ref="iterator" :api="api" :filters.sync="filters">
            <template v-slot:filters="{ busy }">
                <table-filters
                    v-model="filters"
                    :fields="headers"
                    :busy="busy"
                    :list-filters="listFilters"
                    :hide-search-box="true"
                ></table-filters>
            </template>

            <template v-slot:actions>
                <slot name="actions"></slot>
            </template>

            <template slot-scope="{ items }">
                <d-table
                    :items="items"
                    :headers="headers"
                    :filters.sync="filters"
                    class="delivery-table"
                    row-action
                    visible-actions
                    @onRowSelected="onRowSelected"
                >
                    <template v-slot:[`item.deliveryUid`]="{ item }">
                        <template v-if="item.deliveryUid">{{
                            item.deliveryUid
                        }}</template>
                        <template v-else>-</template>
                    </template>
                    <template
                        v-slot:[`item.deliveryJob.orderIdentifiers`]="{ item }"
                    >
                        <router-link
                            v-for="(subitem, index) in item.deliveryJob
                                .orderIdentifiers"
                            :key="index"
                            :to="getEditLink(item)"
                        >
                            {{ index }}
                        </router-link>
                    </template>
                    <template v-slot:[`item.deliveryMethod`]="{ item }">
                        {{
                            $t(`active_delivery_method.${item.deliveryMethod}`)
                        }}
                    </template>

                    <template v-slot:[`item.status`]="{ item }">
                        <delivery-status
                            :item="item"
                            deliveries
                        ></delivery-status>
                    </template>

                    <template
                        v-slot:[`item.deliveryJob.orderCreatedAt`]="{ item }"
                    >
                        {{ item.deliveryJob.orderCreatedAt | transformDate }}
                    </template>

                    <template v-slot:[`item.deliveryJob.pickupTime`]="{ item }">
                        {{ item.deliveryJob.pickupTime | transformDate }}
                    </template>

                    <template
                        v-slot:[`item.deliveryJob.orderDropoffAt`]="{ item }"
                    >
                        <drop-off :item="item" deliveries></drop-off>
                    </template>

                    <template
                        v-slot:[`item.deliveryJob.dropoffAddress`]="{ item }"
                    >
                        <span class="text-nowrap">{{
                            item.deliveryJob.dropoffAddress
                                | toFullName(
                                    'contact_first_name',
                                    'contact_last_name'
                                )
                        }}</span>
                    </template>

                    <template
                        v-slot:[`item.deliveryJob.pickupAddress.full_address`]="{
                            item,
                        }"
                    >
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <div
                                    v-bind="attrs"
                                    class="column-ellipsis"
                                    v-on="on"
                                >
                                    {{
                                        item.deliveryJob.pickupAddress
                                            .contact_company
                                    }}
                                </div>
                            </template>
                            <span>{{
                                item.deliveryJob.pickupAddress.full_address
                            }}</span>
                        </v-tooltip>
                    </template>

                    <template
                        v-slot:[`item.deliveryJob.dropoffAddress.full_address`]="{
                            item,
                        }"
                    >
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <div
                                    v-bind="attrs"
                                    class="column-ellipsis"
                                    v-on="on"
                                >
                                    {{
                                        item.deliveryJob.dropoffAddress
                                            .full_address | removeCountry
                                    }}
                                </div>
                            </template>
                            <span>{{
                                item.deliveryJob.dropoffAddress.full_address
                            }}</span>
                        </v-tooltip>
                    </template>

                    <!-- row actions -->
                    <template v-slot:rowActions="{ item }" class="test">
                        <cancel-delivery-action
                            v-if="item.canBeCancelled"
                            :entity-name="item.email"
                            :entity-id="item.id"
                            :entity-count="
                                item.deliveryJob.activeDeliveryProviderJobCount
                            "
                            :order-id="
                                item.deliveryJob.orderIdentifiers &&
                                Object.keys(item.deliveryJob.orderIdentifiers)
                                    .length > 0
                                    ? item.deliveryJob.orderIdentifiers[
                                          Object.keys(
                                              item.deliveryJob.orderIdentifiers
                                          )[0]
                                      ].toString()
                                    : null
                            "
                            :delivery-job-id="item.deliveryJob.id"
                            :api="api"
                            :confirm-title="
                                $t('texts.confirm_to_cancel_delivery')
                            "
                            @deleted="onEntityDeleted"
                            @failed="onEntityDeleteFailed"
                        ></cancel-delivery-action>
                    </template>
                </d-table>
            </template>
            <!-- batch actions -->
            <template v-slot:batchActions>
                <delete-batch-action
                    :selected-ids="selectedIds"
                    :api="api"
                    @finished="onBatchDeleteFinished"
                    @finishedWithErrors="onBatchDeleteFinishedWithErrors"
                    @canceled="onBatchDeleteCanceled"
                ></delete-batch-action>
            </template>
        </data-iterator>
    </table-container>
</template>

<script>
import routeType from '@/router/routeType'
import RestApiType from '@/api/RestApiType'
import RestApiCollection from '@/api/RestApiCollection'
import Notification from '@/services/Notification/Notification'
import DataIteratorMixin from '@/components/mixins/DataIteratorMixin'
import DataTableMixin from '@/components/mixins/DataTableMixin'
import configType from '@/store/type/configType'
import DropOff from '@/components/elements/DropOff'
import DeliveryStatus from '@/components/elements/DeliveryStatus'
import DeliveriesEvent from '@/pages/deliveries/mixins/DeliveriesEvent'
import Headers from '@/pages/deliveries/mixins/Headers'
import ListFilters from '@/pages/deliveries/mixins/ListFilters'

import { mapGetters } from 'vuex'

export default {
    components: { DeliveryStatus, DropOff },
    mixins: [
        DataIteratorMixin,
        DataTableMixin,
        DeliveriesEvent,
        Headers,
        ListFilters,
    ],

    data() {
        return {
            api: RestApiType.DELIVERIES,
            filters: {
                order: {
                    'deliveryJob.orderCreatedAt': 'desc',
                },
            },
        }
    },
    computed: {
        ...mapGetters({
            currentLocale: configType.getters.CURRENT_LOCALE,
        }),
        tableName() {
            return this.$t('labels.deliveries')
        },
    },

    methods: {
        getEditLink(item, tab = 0) {
            return {
                name: routeType.DELIVERY_EDIT,
                params: { id: item.id },
                query: { tab: tab },
            }
        },
        closeDeliveryProviderJob(itemId) {
            RestApiCollection.get(this.api)
                .closeDeliveryProviderJob(itemId)
                .then(() => {
                    Notification.success(
                        itemId,
                        this.$t('delivery.delivery_provider_job_closed')
                    ),
                        this.updateTableItems()
                })
        },
    },
}
</script>
