export default {
    computed: {
        headers() {
            return [
                {
                    value: 'deliveryUid',
                    text: this.$t('orders.delivery_id'),
                    type: 'string',
                    width: 'max-content',
                },
                {
                    value: 'deliveryJob.orderIdentifiers',
                    text: this.$t('orders.order_id'),
                    type: 'string',
                    width: '60',
                },
                {
                    value: 'deliveryMethod',
                    text: this.$t('labels.delivery_method'),
                    type: 'string',
                    width: '60',
                },
                {
                    value: 'status',
                    text: this.$t('labels.status'),
                    type: 'string',
                    width: '110',
                },
                {
                    value: 'deliveryJob.orderCreatedAt',
                    text: this.$t('orders.created'),
                    type: 'string',
                    width: '130',
                },
                {
                    value: 'deliveryJob.pickupTime',
                    text: this.$t('orders.pickup_time'),
                    type: 'string',
                    width: '130',
                },
                {
                    value: 'deliveryJob.orderDropoffAt',
                    text: this.$t('orders.dropoff_at'),
                    type: 'string',
                    width: '175',
                },
                {
                    value: 'deliveryJob.pickupAddress.full_address',
                    text: this.$t('orders.pickup_address'),
                    type: 'string',
                    sortable: false,
                },
                {
                    value: 'deliveryJob.dropoffAddress.full_address',
                    text: this.$t('orders.destination'),
                    type: 'string',
                    sortable: false,
                },
                {
                    value: 'deliveryJob.dropoffAddress',
                    text: this.$t('orders.customer'),
                    type: 'string',
                    sortable: false,
                },
            ]
        },
    },
}
