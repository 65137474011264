import Event from '@/mixins/Event'

export default {
    mixins: [Event],
    data() {
        return {
            channelDeliveryProviderJobs: null,
            logToConsole: false,
        }
    },
    created() {
        this.channelDeliveryProviderJobs = this.pusher.subscribe(
            'delivery-provider-jobs'
        )
        this.channelDeliveryProviderJobs.bind(
            'create-delivery-provider-job',
            this.createDelivery
        )
        this.channelDeliveryProviderJobs.bind(
            'update-delivery-provider-job',
            this.updateDelivery
        )
    },
    beforeDestroy() {
        if (this.pusher) {
            // this.channelDeliveryProviderJobs.unbind()
            // this.pusher.unsubscribe('delivery-provider-jobs')
            this.pusher.disconnect()
        }
    },
    methods: {
        createDelivery(data) {
            if (data) this.updateTableItems()
        },
        updateDelivery(data) {
            const itemKey = this.findOrder(data.id)
            this.updateItem(data.data, itemKey, this.mapDeliveryProviderJob)
        },
    },
}
