<template>
    <div>
        <page-title>{{ $t('invoices.page_title') }}</page-title>
        <users-table ref="table" @company-filter="companyFilter = $event">
        </users-table>
    </div>
</template>

<script>
import PageTitle from '@/components/layout/components/pageTitle'
import routeType from '@/router/routeType'
import UsersTable from '@/components/pages/invoices/Restaurant'
import AuthMixin from '@/components/mixins/AuthMixin'

export default {
    components: {
        PageTitle,
        UsersTable,
    },
    mixins: [AuthMixin],
    data() {
        return {
            routeType,
            companyFilter: null,
        }
    },
}
</script>
