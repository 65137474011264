<template>
    <div>
        <div>
            <v-card elevation="0" class="mb-4 border-g-e8">
                <v-card-text>
                    <template>
                        <v-breadcrumbs :items="breadcrumbs">
                            <template v-slot:item="{ item }">
                                <v-breadcrumbs-item :disabled="true">
                                    {{ item.text.toUpperCase() }}
                                </v-breadcrumbs-item>
                            </template>
                        </v-breadcrumbs>
                    </template>
                    <v-list-item-group>
                        <template v-if="!currentFolder">
                            <v-list-item
                                v-for="folder in folders"
                                :key="folder.id"
                                @click="setPath(folder)"
                            >
                                <v-list-item-icon>
                                    <v-icon>folder</v-icon>
                                </v-list-item-icon>

                                <v-list-item-content>
                                    <v-list-item-title
                                        v-text="folder.text"
                                    ></v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </template>
                        <template v-if="currentFolder">
                            <v-list-item :key="0" @click="load()">
                                <v-list-item-icon>
                                    <v-icon>folder</v-icon>
                                </v-list-item-icon>

                                <v-list-item-content>
                                    <v-list-item-title>..</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item
                                v-for="file in files"
                                :key="file.id"
                                @click="downloadFile(file.id, file.file_name)"
                            >
                                <v-list-item-icon>
                                    <v-icon>description</v-icon>
                                </v-list-item-icon>

                                <v-list-item-content>
                                    <v-list-item-title
                                        v-text="
                                            file.file_name +
                                            '.' +
                                            file.extension
                                        "
                                    ></v-list-item-title>
                                </v-list-item-content>
                                <v-list-item-action>
                                    <div></div>
                                </v-list-item-action>
                            </v-list-item>
                        </template>
                    </v-list-item-group>
                </v-card-text>
            </v-card>
        </div>
        <overlay :show="busy">
            <template>
                <slot></slot>
            </template>
        </overlay>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ApiClientType from '@/api/RestApiType'
import FormExtend from '@/components/mixins/FormExtend'
import AuthMixin from '@/components/mixins/AuthMixin'
import restaurantType from '@/store/type/restaurantType'
import RestApiCollection from '@/api/RestApiCollection'
import IdFromIri from '@/services/IdFromIri'
import Overlay from '@/components/overlay/Overlay'

export default {
    components: {
        Overlay,
    },
    mixins: [FormExtend, AuthMixin],
    data() {
        return {
            api: ApiClientType.COMPANY_INVOICE,
            dialog: false,
            dialogModify: false,
            dialogModifyFile: false,
            busy: false,
            companyId: null,
            restaurantId: null,
            parentFolderId: null,
            currentFolder: null,
            folders: [],
            files: [],
            selectedFile: null,
            isSelecting: false,
            inputValue: null,
        }
    },
    computed: {
        myEntityId: function () {
            return this.type === 'restaurant'
                ? this.restaurant.id
                : IdFromIri.getId(this.restaurantId)
        },
        breadcrumb: function () {
            return this.currentFolder ? this.currentFolder.text : null
        },
        breadcrumbs: function () {
            return this.currentFolder ? [this.currentFolder] : []
        },
        ...mapGetters({
            restaurant: restaurantType.getters.ACTIVE_RESTAURANT,
        }),
        type: function () {
            return this.restaurant['@type'].toLowerCase()
        },
    },
    mounted() {
        this.load()
    },
    methods: {
        openFolderModal() {
            this.dialog = true
        },
        hideModal() {
            this.dialog = false
        },
        setPath(folder) {
            folder?.id
                ? (this.loadFolderFiles(folder.id),
                  (this.currentFolder = folder))
                : (this.currentFolder = null)
        },
        load() {
            this.busy = true
            this.files = []
            this.folders = []
            this.currentFolder = null
            RestApiCollection.get(this.api)
                .getFolders(this.type, this.myEntityId)
                .then((data) => {
                    this.folders = data
                })
                .catch(() => {})
                .finally(() => {
                    this.busy = false
                })
        },
        loadFolderFiles(folderId) {
            this.busy = true
            RestApiCollection.get(this.api)
                .getFolderFiles(this.type, this.myEntityId, folderId)
                .then((data) => {
                    this.files = data
                })
                .catch(() => {})
                .finally(() => {
                    this.busy = false
                })
        },
        deleteFile(fileId) {
            this.busy = true
            RestApiCollection.get(this.api)
                .deleteFile(
                    this.type,
                    this.myEntityId,
                    this.currentFolder.id,
                    fileId
                )
                .then(() => {})
                .catch(() => {})
                .finally(() => {
                    this.loadFolderFiles(this.currentFolder.id)
                    this.busy = false
                })
        },
        downloadFile(fileId, fileName) {
            this.busy = true
            RestApiCollection.get(this.api)
                .downloadFile(
                    this.type,
                    this.myEntityId,
                    this.currentFolder.id,
                    fileId
                )
                .then((response) => {
                    const blob = new Blob([response], {
                        type: 'application/pdf',
                    })
                    const blobUrl = URL.createObjectURL(blob)
                    const link = document.createElement('a')
                    link.href = blobUrl
                    link.download = fileName
                    link.click()
                    URL.revokeObjectURL(link.href)
                })
                .catch(() => {})
                .finally(() => {
                    this.busy = false
                })
        },
    },
}
</script>
