var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('table-container',[_c('data-iterator',{ref:"iterator",attrs:{"api":_vm.api,"filters":_vm.filters},on:{"update:filters":function($event){_vm.filters=$event}},scopedSlots:_vm._u([{key:"filters",fn:function(ref){
var busy = ref.busy;
return [_c('table-filters',{attrs:{"fields":_vm.headers,"busy":busy,"list-filters":_vm.listFilters,"hide-search-box":true},model:{value:(_vm.filters),callback:function ($$v) {_vm.filters=$$v},expression:"filters"}})]}},{key:"actions",fn:function(){return [_vm._t("actions")]},proxy:true},{key:"default",fn:function(ref){
var items = ref.items;
return [_c('d-table',{staticClass:"delivery-table",attrs:{"items":items,"headers":_vm.headers,"filters":_vm.filters,"row-action":"","visible-actions":""},on:{"update:filters":function($event){_vm.filters=$event},"onRowSelected":_vm.onRowSelected},scopedSlots:_vm._u([{key:"item.deliveryUid",fn:function(ref){
var item = ref.item;
return [(item.deliveryUid)?[_vm._v(_vm._s(item.deliveryUid))]:[_vm._v("-")]]}},{key:"item.deliveryJob.orderIdentifiers",fn:function(ref){
var item = ref.item;
return _vm._l((item.deliveryJob
                            .orderIdentifiers),function(subitem,index){return _c('router-link',{key:index,attrs:{"to":_vm.getEditLink(item)}},[_vm._v(" "+_vm._s(index)+" ")])})}},{key:"item.deliveryMethod",fn:function(ref){
                            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t(("active_delivery_method." + (item.deliveryMethod))))+" ")]}},{key:"item.status",fn:function(ref){
                            var item = ref.item;
return [_c('delivery-status',{attrs:{"item":item,"deliveries":""}})]}},{key:"item.deliveryJob.orderCreatedAt",fn:function(ref){
                            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("transformDate")(item.deliveryJob.orderCreatedAt))+" ")]}},{key:"item.deliveryJob.pickupTime",fn:function(ref){
                            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("transformDate")(item.deliveryJob.pickupTime))+" ")]}},{key:"item.deliveryJob.orderDropoffAt",fn:function(ref){
                            var item = ref.item;
return [_c('drop-off',{attrs:{"item":item,"deliveries":""}})]}},{key:"item.deliveryJob.dropoffAddress",fn:function(ref){
                            var item = ref.item;
return [_c('span',{staticClass:"text-nowrap"},[_vm._v(_vm._s(_vm._f("toFullName")(item.deliveryJob.dropoffAddress, 'contact_first_name', 'contact_last_name' )))])]}},{key:"item.deliveryJob.pickupAddress.full_address",fn:function(ref){
                    var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"column-ellipsis"},'div',attrs,false),on),[_vm._v(" "+_vm._s(item.deliveryJob.pickupAddress .contact_company)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.deliveryJob.pickupAddress.full_address))])])]}},{key:"item.deliveryJob.dropoffAddress.full_address",fn:function(ref){
                    var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"column-ellipsis"},'div',attrs,false),on),[_vm._v(" "+_vm._s(_vm._f("removeCountry")(item.deliveryJob.dropoffAddress .full_address))+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.deliveryJob.dropoffAddress.full_address))])])]}},{key:"rowActions",fn:function(ref){
                    var item = ref.item;
return [(item.canBeCancelled)?_c('cancel-delivery-action',{attrs:{"entity-name":item.email,"entity-id":item.id,"entity-count":item.deliveryJob.activeDeliveryProviderJobCount,"order-id":item.deliveryJob.orderIdentifiers &&
                            Object.keys(item.deliveryJob.orderIdentifiers)
                                .length > 0
                                ? item.deliveryJob.orderIdentifiers[
                                      Object.keys(
                                          item.deliveryJob.orderIdentifiers
                                      )[0]
                                  ].toString()
                                : null,"delivery-job-id":item.deliveryJob.id,"api":_vm.api,"confirm-title":_vm.$t('texts.confirm_to_cancel_delivery')},on:{"deleted":_vm.onEntityDeleted,"failed":_vm.onEntityDeleteFailed}}):_vm._e()]}}],null,true)})]}},{key:"batchActions",fn:function(){return [_c('delete-batch-action',{attrs:{"selected-ids":_vm.selectedIds,"api":_vm.api},on:{"finished":_vm.onBatchDeleteFinished,"finishedWithErrors":_vm.onBatchDeleteFinishedWithErrors,"canceled":_vm.onBatchDeleteCanceled}})]},proxy:true}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }