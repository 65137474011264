export default {
    computed: {
        listFilters() {
            return [
                {
                    key: 'deliveryJob.orders.identifier',
                    label: this.$t('orders.order_id'),
                    inputType: 'search',
                    quick: 'true',
                },
                {
                    key: 'deliveryJob.pickupTime',
                    inputType: 'checkbox',
                    label: 'labels.today_filter',
                    type: 'after',
                    value: this.$date().startOf('day').format(),
                    other: {
                        key: 'deliveryJob.pickupTime',
                        type: 'before',
                        value: this.$date().endOf('day').format(),
                    },
                },
                {
                    key: 'deliveryJob.mergedDeliveryJobs',
                    type: 'exists',
                    inputType: 'checkbox',
                    label: 'labels.merged_delivery_jobs',
                    value: 1,
                },
                {
                    key: 'hasProblems',
                    inputType: 'checkbox',
                    label: 'labels.has_problems',
                    value: 1,
                },
            ]
        },
    },
}
